<template>
   <div>
                        <el-form ref="form" :model="saveForm" label-width="150px" label-position="left" style="margin-top:20px;">
                            <el-row>   
                                <el-form-item label="项目名称" style="width:372px;">
                                    <el-input  v-model="saveForm.projectName" placeholder="请输入项目名称"></el-input>
                                </el-form-item>
                            </el-row>
                            <el-row>   
                                <el-form-item label="项目描述" style="width:372px;">
                                    <el-input v-model="saveForm.projectDescript" type="textarea" autocomplete="off" :rows="3"
                                         placeholder="请输入项目描述"  style="width: 300px;"></el-input>
                                </el-form-item>
                            </el-row>
                            <el-row>
                                <el-form-item label="投放品牌" style="width:372px;">
                                    <el-input  v-model="saveForm.brand" placeholder="请输入品牌名称"></el-input>
                                </el-form-item>

                                <el-form-item v-if="!smartResult.industryId" label="广告主行业" >  <!-- style="margin-left:30px;" -->
                                    <el-select v-model="saveForm.cateId" style="width:225px;" placeholder="请选择广告主行业">
                                        <el-option
                                        v-for="item in categoryList"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                
                                
                            </el-row>
                        </el-form>
                    </div>
                    <div class="button-footer">                      
                        <span >
                            <el-button @click="close()">取消</el-button>
                            <el-button type="primary" @click="onSaveProject">确定</el-button>
                        </span>                    
                    </div>
</template>

<script>
import { mapGetters } from "vuex";
import {SaveProjectSmart,ProjectExport} from "@/api/operate"
import {getToken} from "@/utils/auth";

export default {
    computed: {...mapGetters(['categoryList'])},
    props:['ledDiscount','dxDiscount','lcDiscount','cartList','totalPrice','smartResult','cateid','brandname'],
    data() {
        return {
            saveForm:{
                brand:'',
                cateId:'',
                projectName:'',
                projectDescript:'',
            },
        }
    },

    async mounted() {    
        await this.$store.dispatch('operate/categoryList');    
        if (this.cateid){
            this.saveForm.cateId=this.cateid;
        } 
        if (this.brandname){
            this.saveForm.brand=this.brandname;
        } 
    },

    methods:{
        async onSaveProject(){
            const {projectName,projectDescript,brand,cateId} = this.saveForm;
            var tmpCateId="";
            if (this.smartResult.industryId){
                tmpCateId = this.smartResult.industryId
            }else{
                tmpCateId=cateId;
            }
            if (!projectName){
                this.$message({message: '请输入项目名称',type: 'error'});
                return;
            }
            if (!brand){
                this.$message({message: '请输入品牌名称',type: 'error'});
                return;
            }
            if (!tmpCateId){
                this.$message({message: '请选择广告主行业',type: 'error'});
                return;
            }

            var newJson=[];
            // console.log('aa',this.cartList)
            var weaponId='';
            this.cartList.forEach(element => {
                var obj = {};
                obj= element.item;
                if (element.weaponId){
                    weaponId=element.weaponId;
                }
                // console.log('aa',obj)
                obj.detailId=-1;
                // console.log('bb')
                // console.log('adj',obj.adjLog)
                if (!obj.adjLog){
                    obj.adjLog="";
                }
                newJson.push(obj);
            });
            // console.log('aaaaaaaaaaaaaaaaa',this.cartList,newJson)
            // return;

            var condi = JSON.stringify(newJson);
            // console.log('condi',condi)
            console.log('rr',this.smartResult)
            await SaveProjectSmart({userId:getToken(),
                                   projectId:-1,
                                   projectName,
                                   projectDescript,
                                   cateId:tmpCateId,
                                   brand,
                                   budget:this.totalPrice,
                                   cpm:this.smartResult.CPM,
                                   frequency:this.smartResult.Frequency,
                                   personCnt:this.smartResult.chudarenshu,
                                   reachRate:this.smartResult.Reach,
                                   ledDiscount:this.ledDiscount,
                                   dxDiscount:this.dxDiscount,
                                   lcDiscount:this.lcDiscount,
                                   weaponId,
                                   condi}).then((data)=>{
                                    //    console.log('aa',data)
                                   });
            this.$message({
                        type: 'success',
                        message: '项目保存成功,后续可在《项目列表》菜单中进行查看。'
                    }); 

            this.$emit('close');
            this.$store.commit('cart/emptyCart', );
            this.$router.push({name: 'MyProject'});
      
        },

        close(){
            this.$emit('close');
        },
    }

}
</script>

<style>

</style>