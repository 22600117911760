<template>
    <div class="container" style="height: 1900px;" >   
        <div v-if="active==0">
            <el-row style="width:100%;">
                <el-form ref="form" :model="curForm" label-width="100px" label-position="left" style="width:100%;">
                    <el-form-item label="投放周期" >    
                        <el-date-picker                                
                            v-model="curForm.dateArray"
                            style="width:262px;"
                            type="daterange"
                            unlink-panels
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"                                   
                            :clearable="false"
                            :shortcuts="shortcuts"
                            @change ="handleDateChange"
                            >
                        </el-date-picker>
                    </el-form-item>  
                    <el-form-item  label="版本时长" style="width:372px;">                            
                        <el-select @change="lengthChanged" v-model="curForm.length"  placeholder="请选择版本时长">
                            <el-option
                            v-for="item in lengthList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="每日投放频次" style="width:372px;">                            
                        <el-select @change="timesChanged" v-model="curForm.times"  placeholder="请选择每日投放频次">
                            <el-option
                            v-for="item in timesList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </el-row>
            <div style="font-size:larger;">武器库清单</div>
            <el-tabs v-model="activeName" >
                <el-tab-pane
                        v-for="(item, index) in GetTeSeTaoCanList"
                        :key="index"  :label="item.typeName"  :name="item.typeName"  lazy> 
                    <el-row style="margin-top:5px;">
                        <div class="card-group" style="display:flex;justify-content:space-around;"> 
                                    <div class="group-item" style="display:flex;align-items:center;" v-for="(itemsub,indexsub) in item.tstci" :key="indexsub">
                                        <div style="margin-left:5px; margin-top:25px;">
                                        <el-card class="box-card" >
                                            <div style="display:flex;">
                                                <div style="width:30%;cursor: pointer;">
                                                    <el-image
                                                         @click="onClickTaocan(itemsub)"
                                                        style="width: 100%;border-radius: 20px;margin-left: -5px;"
                                                        :src="itemsub.img"
                                                        :fit="fit"></el-image>
                                                </div>
                                                <div  style="width:70%;">
                                                    <div class="clearfix" style="height: 25px;">
                                                        <el-button   class="product-name" style="margin-left:10px;" type="text" @click="onClickTaocan(itemsub)">{{itemsub.subTypeName}}</el-button>
                                                    </div>
                                                
                                                    <div  class="text item" style="margin-left:8px;">                                            
                                                        <div style="margin-top:20px;">
                                                            <el-row>
                                                                <el-col :span="3" class="subtitle">折扣</el-col>
                                                                <el-col :span="21">{{itemsub.discount}}折</el-col>
                                                            </el-row>   
                                                            <el-row style="margin-top:5px;">
                                                                <el-col :span="3" class="subtitle">说明</el-col>
                                                                <el-col :span="21">{{itemsub.description}}</el-col>
                                                            </el-row>                                                    
                                                        </div>                                                
                                                    </div>
                                                </div>
                                            </div>                                         
                                        </el-card>
                                        </div>
                                        <el-dialog title="产品明细" v-model="setDetailDialogVisible" width="70%" @open="open()"
                                            :modal-append-to-body='false'
                                            :close-on-click-modal='false'
                                            append-to-body>
                                            <product-detail @close="closeDetail" :cur-item="curItem" :table-data="tableData"/>
                                            <!-- :group-media-list="groupDetailMediaList" -->
                                        </el-dialog>
                                        <el-dialog title="设置投放计划" v-model="setPlanVisible" width="40%" @open="open()"
                                            :modal-append-to-body='false'
                                            :close-on-click-modal='false'
                                            v-if="setPlanVisible"
                                            append-to-body>
                                            <set-plan @close="setPlanVisible=false" :s-date="sDate" :e-date="eDate" :times="times" :cur-item="curItem" :table-data="tableData"/>
                                            <!-- :group-media-list="groupDetailMediaList" -->
                                        </el-dialog>

                                        <el-dialog title="套餐明细" v-model="viewPackageVisible" width="40%" @open="open()"
                                            :modal-append-to-body='false'
                                            :close-on-click-modal='false'
                                            append-to-body>
                                            <package-detail @close="viewPackageVisible=false" :package-data="packageMediaList"/>                    
                                        </el-dialog>


                                    </div>
                                </div>
                    
                    </el-row>
            
                                            
                    
                </el-tab-pane>
                <!-- <el-tab-pane label="航母套餐"  name="航母套餐"  lazy> </el-tab-pane> -->
            </el-tabs>
        </div>
        <div v-else>
            <el-button   class="product-name" style="margin-left:40px;" type="text" >{{curItem.subTypeName}}</el-button>
            <div>
                <div style="display:flex;justify-content:space-around;">
                    <div style="width:55%;line-height:2.0;">
                        <el-card    class="cardstyle" shadow="hover">
                            <div slot="header" class="clearfix">
                                <span><i class="el-icon-s-unfold" style="color:#3B54C5;"></i>投放效果</span>
                                <el-button :disabled="changed" style="float: right; padding: 3px 0" type="text" v-if="productList.length>0" @click="saveProject">保存方案</el-button>
                            </div>
                            <el-divider style="margin-top:-5px;"></el-divider>
                            <el-row>
                                <!-- <el-col :span="10">{{curLedDiscount<10 || curDxDiscount<10?'折扣费用(万元)','刊例费用(万元):'}}</el-col> -->
                                <el-col v-if="curLedDiscount<10 || curDxDiscount<10 || curLcDiscount<10" :span="10" class="grid-num-small">折扣费用(万元):</el-col>
                                <el-col v-else :span="10" class="grid-num-small">刊例费用:</el-col>
                                <el-col :span="14">￥{{budget}}(万元)</el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="10">
                                    <el-tooltip class="item" effect="dark" placement="right" >
                                        <template v-slot:content> 
                                            <div >
                                                <ul>
                                                    <li>即Cost per mill, 是指某一个营销活动，平均获得一千人次的接触度所需要花费的成本，以货币单位表示.</li>
                                                    <li>该指标可用于评估广告投放的经济性，评估不同市场的广告成本效益</li>
                                                </ul>
                                            </div>
                                        </template>
                                        <span class="grid-num-small">CPM:</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="14">￥{{resultData.CPM}}(元)</el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="10">
                                    <el-tooltip class="item" effect="dark" placement="left-start" content="某个广告在一定时期内被观众接触的总人次，以千人次表示" >
                                        <span class="grid-num-small">累计接触度:</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="14"><span >{{resultData.Frequency}}(千人次)</span></el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="10">
                                    <el-tooltip class="item" effect="dark" placement="left-start" >
                                        <template v-slot:content> 
                                            <div >
                                                <ul>
                                                    <li>到达率是一个时间上的纵向累积指标，它考察特定时间段内观众收看某一媒体或广告<br/>
                                                        （或某一广告计划所能覆盖）的不重复的人数（或比例），反映了接触的受众规模。</li>
                                                    <li>通俗解释到达率(千人)就是看到该广告的总人数</li>
                                                </ul>
                                            </div>
                                        </template>                        
                                        <span class="grid-num-small">到达率:</span>                        
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="14"><span >{{resultData.chudarenshu}}(千人)</span></el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="10">
                                    <el-tooltip class="item" effect="dark" placement="left-start"  >
                                        <template v-slot:content> 
                                            <div >
                                                <ul>
                                                    <li>一个广告排期每条或总体被观众收看的次数</li>
                                                    <li>暴露频次用于广告投放计划传播深度的评估</li>
                                                    <li>算法：累计接触度(千人次)除以到达率(千人)</li>
                                                </ul>
                                            </div>
                                        </template>
                                        <span class="grid-num-small">暴露频次:</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="14"><span>{{resultData.Reach}}(次)</span></el-col>
                            </el-row>
                        </el-card>
                    </div>
                    <div>  
                        <el-card class="cardstyle" style="margin-left: 0px; height: 220px;"  shadow="hover">
                            <div slot="header" class="clearfix" style="margin-top:7px;">
                                <span><i class="el-icon-s-unfold" style="color:#3B54C5;"></i>折扣设置</span>                            
                            </div>
                            <el-divider style="margin-top:9px;"></el-divider>
                            <div v-if="cartHaveLed"> LED <span style="color:red;margin-left:2px;">{{curLedDiscount==10?'无折扣':curLedDiscount+'折'}}</span>
                                <div>
                                    <el-input-number style="width: 135px;margin-top:5px;" v-model="curLedDiscount" :precision="1" :step="0.1" :min="1" :max="10" @change="onSlideChange(1)"></el-input-number>
                                </div>
                            </div>
                            <div v-if="cartHaveDx" style="margin-top:10px;"> 灯箱<span style="color:red;margin-left:2px;">{{curDxDiscount==10?'无折扣':curLedDiscount+'折'}}</span>折
                                <div>
                                    <el-input-number style="width: 135px;margin-top:5px;" v-model="curDxDiscount" :precision="1" :step="0.1" :min="1" :max="10" @change="onSlideChange(2)"></el-input-number>
                                </div>
                            </div>   
                            <div v-if="cartHaveLc" style="margin-top:10px;"> 列车<span style="color:red;margin-left:2px;">{{curLcDiscount==10?'无折扣':curLcDiscount+'折'}}</span>折
                                <div>
                                    <el-input-number style="width: 135px;margin-top:5px;" v-model="curLcDiscount" :precision="1" :step="0.1" :min="1" :max="10" @change="onSlideChange(3)"></el-input-number>
                                </div>
                            </div>              
                            
                        </el-card>
            
                    </div>
                    <div>
                        <!-- <div style="display:flex;justify-content:center;margin-top:15px;"> -->
                            <el-button type="primary" @click="active=0">返回</el-button>
                        <!-- </div> -->
                    </div>
                            
                    <!-- <div style="width:45%;line-height:2.0;">
                        <el-card   class="cardstyle" style="margin-left: 0px;    height: 220px;" shadow="hover" >
                            <div slot="header" class="clearfix">
                                <span><i class="el-icon-s-unfold" style="color:#3B54C5;"></i>技术卡片</span>                            
                            </div>
                            <el-divider style="margin-top:3px;"></el-divider>
                            
                            <el-row>
                                <el-col :span="6">战略:</el-col>
                                <el-col :span="18">{{weaponName}}</el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="6">投放周期:</el-col>
                                <el-col :span="18">{{transferCondition.sDate}}至{{transferCondition.eDate}}</el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="6">媒介类型:</el-col>
                                <el-col :span="18">{{mediaTypeName}}</el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="6">投放区域:</el-col>
                                <el-col :span="18"><span v-html="areaName"></span></el-col>
                            </el-row>
                          </el-card>
                    </div> -->
                </div>
            </div>
            <div>
                <el-form ref="form" :model="searchForm" label-width="120px" style="margin-top:20px;" label-position="left">
                    <el-row>
                        <el-col :span="10">
                            <el-form-item label="关键字" >
                                <el-input  v-model="searchForm.key" style="width:200px;" placeholder="可输入地区/车站/位置/套餐关键字进行检索"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="10">
                            <el-form-item label="自动重算" >
                                <el-switch
                                v-model="searchForm.auto"
                                active-color="#13ce66"
                                inactive-color="#ff4949"
                                active-text="是"
                                inactive-text="否"
                                @change="changeAuto">
                                </el-switch>
                            </el-form-item>
                            
                            
                        </el-col>
                        <el-col :span="4">
                                <el-button v-if="!searchForm.auto" type="primary" @click="onCalculateByHand">计算</el-button>
                            </el-col>
                    </el-row>
                </el-form>
            </div>

            <div>
                <!-- :data="productList"  -->
                <el-table               
                    :data="productList.filter(data => !searchForm.key || data.station.toLowerCase().includes(searchForm.key.toLowerCase())
                                             || data.position.toLowerCase().includes(searchForm.key.toLowerCase())
                                             || data.area.toLowerCase().includes(searchForm.key.toLowerCase())
                                             || data.purename.toLowerCase().includes(searchForm.key.toLowerCase()))"
                    border :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                            style="width: 100%;" ref="Table">
                    <el-table-column prop="area" width="80px;" align="center" label="操作" >
                        <template v-slot="scope">
                            <el-checkbox v-model="scope.row.selected"  @change="onFreeChange(scope.row)">选用</el-checkbox>                                
                        </template>
                    </el-table-column>
                    <el-table-column prop="area" align="center"  width="110" label="大区/路局" ></el-table-column>
                    <el-table-column prop="station" align="center" label="车站/所"  width="130">
                        <template v-slot="scope">
                            <el-button type="text" @click="openStation(scope.row.station)">{{scope.row.station}}</el-button>                                    
                        </template>
                    </el-table-column>
                    <el-table-column prop="position" align="center"  width="100" label="位置" ></el-table-column>
                    <el-table-column prop="purename" align="center"  width="100" label="套餐名称" ></el-table-column>
                    <el-table-column prop="typeName" align="center" width="80" label="资源类型" ></el-table-column>
                    <el-table-column prop="totalCount" align="center" width="80" label="资源数量" ></el-table-column>
                    <el-table-column prop="medias" align="center"   sortable label="媒体资源">
                        <template v-slot="scope">
                            <div class="group-item" v-for="(item,index) in scope.row.mediaInfo" :key="index">
                                <span v-if="scope.row.mediaInfo.length<=5">
                                    <div v-if="item.images.length>0" style="color: #409EFF;cursor: pointer;" @click="openMedia(item.images)">{{item.mediaCode}}</div>
                                    <div v-else>{{item.mediaCode}}</div>
                                </span>
                                <span v-else-if="index<5 || (index>=5 && scope.row.showMore)">
                                    <div v-if="item.images.length>0" style="color: #409EFF;cursor: pointer;" @click="openMedia(item.images)">{{item.mediaCode}}</div>
                                    <div v-else>{{item.mediaCode}}</div>                                    
                                </span>
                            </div>    
                            <div v-if="scope.row.mediaInfo.length>5" style="color:#409EFF">
                                <span v-if="scope.row.showMore" @click="scope.row.showMore=false">收起</span><span v-else  @click="scope.row.showMore=true">更多</span>
                            </div>                    
                        </template>
                    </el-table-column>
                    <el-table-column prop="totalCount" align="center" width="80px" label="是否赠送" >
                        <template v-slot="scope">
                            <!-- <el-switch v-model="scope.row.isFree" active-text="赠送" inactive-text="非赠送" @change="onFreeChange(scope.row)"></el-switch>   -->
                            <div v-if="scope.row.canFree==1">
                            <el-checkbox v-model="scope.row.isFree" @change="onFreeChange(scope.row)">是</el-checkbox>        
                            </div>
                            <div v-else>
                                <el-tooltip  class="item" effect="dark" placement="right" content="不可赠送，详情请咨询高铁资源中心。">      
                                    <div style="margin-top: -1px;">不可赠送</div>
                                </el-tooltip>
                            </div>         
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            
            <div>
                <div style="display:flex;justify-content:center;margin-top:15px;">
                    <el-button type="primary" @click="active=0">返回</el-button>
                </div>
            </div>

            <el-dialog title="资源图片" v-model="viewMediaVisible" width="40%" @open="open()"
                                    :modal-append-to-body='false'
                                    :close-on-click-modal='false'
                                    append-to-body>
                <media-detail @close="viewMediaVisible=false" :image-data="imageList"/>
            </el-dialog>

            <el-dialog title="车站介绍" v-model="viewStationVisible" width="80%"  @open="open()"
                                        :modal-append-to-body='false'
                                        :close-on-click-modal='false'
                                        v-if="viewStationVisible"
                                        append-to-body>
                    <div style="height:2800px;">
                        <station @close="viewStationVisible=false" :station="station"/>
                    </div>
            </el-dialog>

            <el-dialog title="保存方案" v-model="setSaveDialogVisible" width="40%" @open="open()"
                                        :modal-append-to-body='false'
                                        :close-on-click-modal='false'
                                        append-to-body>
                        <set-save  @close="setSaveDialogVisible=false" 
                            :total-price="budget" 
                            :led-discount="curLedDiscount" 
                            :dx-discount="curDxDiscount" 
                            :lc-discount="curLcDiscount" 
                            :smart-result="transferData"
                            :cart-list="saveList" />
        </el-dialog>
    
    </div>
    </div>
    
</template>

<script>
import { mapGetters } from "vuex";
import {getMonthDateArrayByPrePostSafari} from "@/utils/time";
import MediaDetail from '@/views/Business/Smart/MediaDetail';
import Station from '@/views/Business/Station';
import SetSave from './SetSave.vue';

export default {
    name: 'baseform',
    components:{MediaDetail,Station,SetSave},
    computed: {...mapGetters(['GetTeSeTaoCanList','CalculateMultiSmart_WithoutBudget','GetCartData'])},
    data() {
        return {
            title:'',
            curForm:{
               dateArray:getMonthDateArrayByPrePostSafari({date: new Date(), preDelayNum: 0, postDelayNum: 2, formatValue: 'yyyy-M'}),
               length:'15',
               times:100
            },   
            activeName: '导弹精准'  ,
            active:0,      
            curItem:{}, 

            lengthList:[{label:'10秒',value:'10'},{label:'15秒',value:'15'}],
            timesList:[{label:'100次',value:100},{label:'200次',value:200},{label:'300次',value:300},{label:'500次',value:500},
                {label:'1000次',value:1000}],
            transferCondition:{},
            productList:[],
            budget:0,
            cartHaveLed:false,
            cartHaveDx:false,
            resultData:{
                CPM:0,
                Frequency:0,
                chudarenshu:0,
                Reach:0
            },
            curLedDiscount:0,
            curDxDiscount:10,
            curLcDiscount:10,

            viewMediaVisible:false,
            viewStationVisible:false,
            setSaveDialogVisible:false,
            station:'',
            imageList:[],

            searchForm:{
                key:'',
                auto:true,
            },
            saveList:[],
            changed:false,
            transferData:{},
        };
    },
    watch:{
        
    },
    
    async mounted() {         
        await this.$store.dispatch('operate/GetTeSeTaoCanList');
    },

   
    methods: {
       async onClickTaocan(item){
        this.curItem=item;
        const {dateArray: [sYM, eYM]} = this.curForm;            
        if (!sYM){
            this.$message({message: `请设置投放周期！`,type: 'error'});
            return;
        }
        this.curLedDiscount = item.discount;
        this.curDxDiscount = item.discount;
        this.curLcDiscount = item.discount;
        var sDate = new Date(sYM).toLocaleDateString();
        var eDate = new Date(eYM).toLocaleDateString();

        const loading = this.$loading({
            lock: true,
            text: '玩命检索中...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        });

        await this.$store.dispatch('operate/GetCartData',{sDate,eDate,times:this.curForm.times,length:this.curForm.length
            ,packageIds:this.curItem.id,typeId:6,sids:'',tids:''}).then(()=>{
            this.productList=this.GetCartData[0];     
            // console.log('productList',this.GetCartData)
            this.checkMediaType();                          

            this.transferCondition.sDate=sDate;
            this.transferCondition.eDate=eDate;
            this.transferCondition.times=this.curForm.times;
            this.transferCondition.length=this.curForm.length;
            this.transferCondition.isFree=false;
            setTimeout(async () =>{
                if (this.productList.length==0){
                    // this.$message({message: `这下难倒我了，居然没算出来，换个姿势再试试`,type: 'error'});
                    this.$alert('太深奥了，居然没算出来，换个姿势再试试', '无有效数据', {
                        confirmButtonText: '好的',
                        callback: action => {
                            // this.$message({
                            // type: 'info',
                            // message: `action: ${ action }`
                            // });
                        }
                    });
                }else{
                    console.log('ddddd')
                    await  this.calculate();
                    this.active=1;
                }
                loading.close();   
            },100);

                                        
        })


        this.active=1;
       },

        onSlideChange(){
            //计算budget
            if (this.searchForm.auto){
                // this.checkMediaType();
                this.calculate();
            }else{
                this.changed=true;
            }
            // this.calculate();
            
        },

        onFreeChange(item){
            // console.log("switch",this.productList);
            if (this.searchForm.auto){
                this.checkMediaType();
                this.calculate();
            }else{
                this.changed=true;
            }
        },

        openStation(station){
            this.station=station;
            this.viewStationVisible=true;
        },

        openMedia(items){
            // console.log('aaa',items)
            this.imageList=items;
            this.viewMediaVisible=true;
        },

        changeAuto(){
            if (this.searchForm.auto){
                // this.changeCalculate();
                this.checkMediaType();
                this.calculate();
            }
        },

        onCalculateByHand(){
            this.checkMediaType();
            this.calculate();
        },

       checkMediaType(){
            this.cartHaveLed=false;
            this.cartHaveDx=false;
            this.cartHaveLc=false;
            
            this.productList.forEach(ele => {
                if (ele.groupLevel==1 && ele.isFree==false && ele.selected==true){
                    if (ele.typeIds=='1'){
                        this.cartHaveLed=true;
                    }
                    if (ele.typeIds=='2' || ele.typeIds=='3'){
                        this.cartHaveDx=true;
                    }
                    if (ele.typeIds=='4'){
                        this.cartHaveLc=true;
                    }
                }
            });
            // console.log('check',this.cartHaveLed,this.cartHaveDx)
        },
       
       async calculate(){
            const loading = this.$loading({
                lock: true,
                text: '智能计算中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            this.changed=false;
            const{sDate,eDate,times,length}=this.transferCondition;
            var calcuList=[];
            this.productList.forEach(ele => {
                // ele.detailId=-1;
                if (ele.selected==true){
                    var tmp={}
                    tmp.sDate=sDate,
                    tmp.eDate=eDate,
                    tmp.times=times;
                    tmp.length=length;
                    tmp.groupId=ele.groupId;
                    tmp.isFree=ele.isFree;
                    // ele.groupLevel=ele.groupLevel;
                    // ele.totalPrice=0;
                    // ele.adjLog='';
                    calcuList.push(tmp);
                }
            });
            var condi = JSON.stringify(calcuList);

            // console.log('condi',condi)
            var ip = localStorage.getItem('Ip');
            var cityName = localStorage.getItem('cityName');
            
            await this.$store.dispatch('operate/CalculateMultiSmart_WithoutBudget',{ip,cityName,ledDiscount:this.curLedDiscount,dxDiscount:this.curDxDiscount,lcDiscount:this.curLcDiscount,condi}).then(res=>{
                    this.resultData=this.CalculateMultiSmart_WithoutBudget;                     
                    this.budget = this.resultData.feiyong;

                    loading.close();
                    // this.viewMediaVisible=true;
                });

            // await this.$store.dispatch('operate/CalculateMultiSmart',{ip,cityName,condi}).then(res=>{
            //     loading.close();
            // });
        },

        saveProject(){
            
            this.saveList=[];       
            const{sDate,eDate,times,length}=this.transferCondition;
            this.productList.forEach(ele => {
                if (ele.selected==true){
                    var tmp={};
                    // tmp.detailId=-1;
                    tmp.sDate=sDate;
                    tmp.eDate=eDate;
                    tmp.groupId=ele.groupId;
                    tmp.groupLevel=1;                
                    tmp.times=times;
                    tmp.length=length;
                    if (ele.isFree){
                        tmp.totalPrice=0;
                    }
                    else{
                        tmp.totalPrice=-1;
                    }
                    tmp.haveSelectListIds='';
                    tmp.adjLog='';
                    tmp.freeIds='';

                    var obj={};
                    obj.count=1;
                    obj.groupId=ele.groupId;
                    obj.detailId=-1;
                    obj.item=tmp;
                    obj.weaponId=0;//this.weaponId;
                    this.saveList.push(obj);
                }

            });
            
            this.transferData={
                Frequency:this.resultData.Frequency,
                chudarenshu:this.resultData.chudarenshu,
                Reach:this.resultData.Reach,
                CPM:this.resultData.CPM,
                budget:this.budget,
                industryId:'',
            }            
            
            this.setSaveDialogVisible=true;
        },

        open() {
            // console.log("do open");
            this.$nextTick(() => {
                //  执行echarts方法
            });
        },

    }
};
</script>

<style scoped>

.slash{
    width:100px;
    height:1px;    
    transform:rotate(-40deg);
    margin-top: -15px;
    margin-left: -45px;
    /* display:flex;
    justify-content: center;
    align-items: center; */
   
}
.tag{
    cursor: pointer;margin-left:15px;
    font-size: larger;
}
.tagSel{
    cursor: pointer;margin-left:15px;
    font-size:x-large;
    background-color: #409EFF;
    color: white;
}

.ok{
    border-bottom:30px solid green;
}

.delay{
    border-bottom:30px solid red;
}

.ready{
    border-bottom:30px solid #999;
}

.empty{
    border-bottom:30px solid #999;
}

.slash div{
    margin-left:30px;
    padding-top: 7px;
}


.sub-title{
    font-size: large;
    margin-top:4px;
}
.item-pre{
    font-size: larger;
}
.button-footer{
    display: flex;
    flex-direction:row-reverse;
}
.product-name{
    font-size: x-large;
    /* margin-left:25px; */
    margin-top:-20px;
}
.card-group{
    display:flex;
    flex-wrap:wrap;
    justify-content:center;
}
.group-item{
    padding: 1px;
    margin-left: 1px;
    
}
.box-card {
    width: 480px;
    
  }
   .text {
    font-size: 14px;
  }

  .item {
    /* margin-bottom: 18px; */
    margin-top:18px;
  }

  /* .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both
  } */
 

.transition-box {
    margin-bottom: 10px;
    width: 200px;
    height: 100px;
    border-radius: 4px;
    background-color: #409EFF;
    text-align: center;
    color: #fff;
    padding: 40px 20px;
    box-sizing: border-box;
    margin-right: 20px;
  }

.schart-box {
    display: inline-block;
    margin: 20px;
}
.schart {
    width: 600px;
    height: 400px;
}
.content-title {
    clear: both;
    font-weight: 400;
    line-height: 50px;
    margin: 10px 0;
    font-size: 22px;
    color: #1f2f3d;
}


.grid-content {
    display: flex;
    align-items: center;
    flex-direction:column;
    /* height: 120px; */
}

.grid-subcontent {
    display: flex;
    align-items: center;
    height: 50px;
}

.grid-cont-right {
    flex: 1;
    text-align: center;
    font-size: 14px;
    color: #999;
}

.grid-num {
    font-size: 15px;
    /* font-weight: bold; */
}

.grid-num-small {
    font-size: 16px;
    font-weight: bold;
}

.grid-subnum {
    font-size: 20px;
    font-weight: bold;
}

.grid-con-icon {
    font-size: 50px;
    width: 100px;
    height: 100px;
    text-align: center;
    line-height: 100px;
    color: #fff;
    border-radius: 50%;
    /* margin-left: 80px; */
}
.grid-subcon-icon{
    font-size: 30px;
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    color: #fff;
}

.grid-con-0 .grid-subcon-icon {
    background: rgb(184, 223, 223);
}

.grid-con-0 .grid-num {
    color: rgb(184, 223, 223);
}

.grid-con-1 .grid-con-icon {
    background: rgb(45, 140, 240);
}

.grid-con-1 .grid-num {
    color: rgb(45, 140, 240);
}

.grid-con-2 .grid-con-icon {
    background: rgb(100, 213, 114);
}

.grid-con-2 .grid-num {
    color: rgb(45, 140, 240);
}

.grid-con-3 .grid-con-icon {
    background: rgb(242, 94, 67);
}

.grid-con-3 .grid-num {
    color: rgb(242, 94, 67);
}

.grid-con-4 .grid-con-icon {
    background: rgb(132, 94, 67);
}

.grid-con-4 .grid-num {
    color: rgb(132, 94, 67);
}

.grid-con-5 .grid-con-icon {
    background: rgb(66, 94, 67);
}

.grid-con-5 .grid-num {
    color: rgb(66, 94, 67);
}

.grid-con-6 .grid-con-icon {
    background: rgb(66, 136, 67);
}

.grid-con-6 .grid-num {
    color: rgb(66, 136, 67);
}

.clearfix{
    margin-top: -5px;
}
.subtitle{
    font-weight: bolder;
    padding-right: 5px;
}


</style>